@import "variables";

.brand-link {
    .logo-xl {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 250px;
        height: 57px;
        max-height: 57px;
    }

    .logo-xs {
        top: 0;
        left: 0;
        bottom: 0;
        width: 74px;
        height: 57px;
        max-height: 57px;
    }
}

.input-group-outline {
    .input-group-text {
        border-right: 0;
        background-color: transparent;
    }

    .form-control {
        border-left: 0;
        padding-left: 0;

        &:focus {
            border-color: #ced4da;
        }
    }
}


.toasts-top-right {
    top: 30px;
    right: 30px;

    .toast {
        min-width: 300px;

        .toast-header {
            padding: 10px 20px;
            font-size: 16px;
        }
    }
}

.help-block {
    margin-top: 0.25rem;
    font-size: 0.90rem;
    color: #6c757d;
}

.field-validation-error {
    margin-top: 0.25rem;
    font-size: 0.90rem;
}

.card {
    & > .card-header > .card-tools {
        margin: 0;
        margin-right: -0.625rem;
    }

    &.card-tabs > .card-header > .card-tools {
        margin: 0.3rem 0.5rem;
    }
}

// https://github.com/bantikyan/icheck-bootstrap/issues/10#issuecomment-847651587
[class*="icheck-"] > input {
    z-index: 1;
    cursor: pointer;
    width: 22px;
    height: 22px;
}

.sticky-top-scrollable {
    height: 100vh;
    padding-right: 10px;
}

.control-sidebar-bg, .control-sidebar {
    width: 300px;
    right: -300px;
}

.control-sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: -15px;
    line-height: 1;

    > li {
        pointer-events: none;
        cursor: default;
        margin: 30px 0px;

        .control-sidebar-subheading {
            a {
                pointer-events: none;
            }

            h4 {
                display: block;
                font-weight: 400;
                font-size: 14px;
            }

            & [data-toggle] {
                pointer-events: all;
                cursor: pointer;
                color: #b8c7ce;

                &:hover {
                    color: $primary-bg;
                }
            }
        }

        .control-sidebar-subfooter {
            margin-top: 6px;
            margin-bottom: 0;
            padding-bottom: 0;
            color: #b8c7ce;
            display: block;

            a {
                pointer-events: all;
                cursor: pointer;
            }
        }
    }
}
