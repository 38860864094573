$primary-bg: #3c8dbc;
$primary-text: #fff;

$danger-bg: #dd4b39;
$danger-text: #fff;

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}