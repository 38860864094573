@import "variables";

.wysiwyg {
    .btn {
        background: red;
    }
}

.mce-ico.mce-i-fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}