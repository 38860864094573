@import "variables";

.image-uploader {
    min-height: 10rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    position: relative;
    cursor: pointer;

    &.drag-over {
        background-color: #ced4da;
    }

    input[type=file] {
        width: 0;
        height: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .uploaded {
        padding: .5rem;
        line-height: 0;

        .uploaded-image {
            display: inline-block;
            width: calc(16.6666667% - 1rem);
            padding-bottom: calc(16.6666667% - 1rem);
            height: 0;
            position: relative;
            margin: .5rem;
            background: #f3f3f3;
            cursor: default;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }

            .delete-image {
                display: none;
                cursor: pointer;
                position: absolute;
                top: .2rem;
                right: .2rem;
                border-radius: 50%;
                padding: .3rem;
                line-height: 1;
                background-color: rgba(0,0,0,.5);
                -webkit-appearance: none;
                border: none;

                i {
                    display: block;
                    color: #fff;
                    width: 1.4rem;
                    height: 1.4rem;
                    font-size: 1.4rem;
                    line-height: 1.43rem;
                }
            }

            &:hover .delete-image {
                display: block;
            }
        }
    }

    .upload-text {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #ced4da;

        span {
            display: block;
        }

        .fa-2x {
            font-size: 3rem;
            margin-bottom: .5rem;
        }
    }

    &.has-files {
        .upload-text {
            display: none;
        }
    }
}

@media screen and (max-width: 1366px) {
    .image-uploader .uploaded .uploaded-image {
        width: calc(20% - 1rem);
        padding-bottom: calc(20% - 1rem);
    }
}

@media screen and (max-width: 992px) {
    .image-uploader .uploaded {
        padding: .4rem;
    }

    .image-uploader .uploaded .uploaded-image {
        width: calc(25% - .8rem);
        padding-bottom: calc(25% - .4rem);
        margin: .4rem;
    }
}

@media screen and (max-width: 786px) {
    .image-uploader .uploaded {
        padding: .3rem;
    }

    .image-uploader .uploaded .uploaded-image {
        width: calc(33.3333333333% - .6rem);
        padding-bottom: calc(33.3333333333% - .3rem);
        margin: .3rem;
    }
}

@media screen and (max-width: 450px) {
    .image-uploader .uploaded {
        padding: .2rem;
    }

    .image-uploader .uploaded .uploaded-image {
        width: calc(50% - .4rem);
        padding-bottom: calc(50% - .4rem);
        margin: .2rem;
    }
}