@import "variables";

.card {
    .card-body {
        &.p-0 {
            .table {
                border: 0;
                width: 100% !important;

                thead th,
                thead td {
                    border-bottom: 0;
                    border: 0;
                }

                thead > tr > th:first-of-type,
                thead > tr > td:first-of-type,
                tbody > tr > th:first-of-type,
                tbody > tr > td:first-of-type,
                tfoot > tr > th:first-of-type,
                tfoot > tr > td:first-of-type {
                    padding-left: 1.5rem;
                }
            }
        }

        .table-selectable {
            tr.selected {
                .fa-square {
                    font-weight: 900;

                    &::before {
                        content: "\f14a";
                    }
                }
            }
        }
    }

    .card-header {
        .card-tools {
            & > .input-group {
                display: inline-flex;
                width: auto;
            }

            & > .btn-group {
                margin-top: -2.5px;
            }
        }
    }

    .card-paging {
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding: 0 1.25rem;

        .dataTables_info,
        .dataTables_length,
        .dataTables_paginate,
        .select-info {
            display: inline-block;
        }

        .select-info {
            margin-left: 10px;
            display: none;
        }

        .dataTables_length {
            margin-right: 10px;
        }
    }
}

.dt-control {
    cursor: pointer;
}
