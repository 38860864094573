@import "variables";

.item {
    position: relative;

    .item-tools {
        position: absolute;
        top: -18px;
        right: -13px;
        background-color: white;
    }
}

.form-control[type="file"] {
    height: calc(2.25rem + 8px);
}

.form-group.form-group-invalid {
    .form-control,
    .select2-container--default .select2-selection--multiple {
        border-color: #dc3545;
        background-color: #dc354514;
    }
    
    label {
        color: #dc3545;
    }
}

.field-validation-error {
    display: inline-block;
    margin-top: 0.5rem;
}