@import "variables";

.photos {
    margin: 7px -7px;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}
[data-toggle="photo"] {
    position: relative;
    display: flex !important;
    margin: 7px;
    width: 200px;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 0.25rem;
    align-items: center !important;
    justify-content: center !important;

    [data-toggle="remove"] {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    [data-toggle="download"] {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    [data-info="true"] {
        width: 32px;
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -16px;
    }

    &[data-remote] {
        cursor: pointer;
    }
}